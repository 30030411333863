<template>
<div>

  <b-modal id="modal-delete-profile" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Are you sure?
    </template>
    <div class="d-block">
      <p>Are you sure you want to delete this profile?</p>
      <p><strong>{{userDisplay}}</strong></p>
      <p>This action is irreversible.</p>
      <label for="verificationCode">Type in "DELETE" to activate the delete button</label>
      <input :value="deleteBlocker.toUpperCase()" @input="deleteBlocker = $event.target.value.toUpperCase()" type="text" placeholder="" class="form-control">
    </div>
    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-delete-profile')">Cancel</button>
        <button class="btn btn-block btn-lg btn-danger ml-1" v-on:click="deleteProfile()" :disabled="deleteBlocker !== 'DELETE'">Yes, delete</button>
      </div>
    </div>
  </b-modal>

  <div class="container-fluid wrapper-medium mt-3">

    <div class="row mb-3">
      <div class="col-12 col-md-6 mb-3">
          <b-link class="btn btn-link btn-sm pl-0" :to="'/profile/' + id "><span class="icon icon-left-open mr-2 pl-0"></span>Parent & Child Profile</b-link>
          <h2 class="anim-load1 mt-2 mb-3">Edit Parent & Child profile</h2>
          <h4>{{userDisplay}}</h4>
          <hr>
          <h5 class="mb-3">Danger zone</h5>
          <b-link class="btn btn-danger btn-sm" v-on:click="$bvModal.show('modal-delete-profile')">Delete this profile</b-link>
      </div>
      <div class="col-12 col-md-6">
        <div class="card mb-3">
          <div class="card-body">
            <ValidationObserver ref="form">
              <b-form @submit.prevent="submit" class="mb-3">

                <div class="form-group" ref="parentFirstName">
                  <label for="parentFirstNameInput">Parent's first name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="Parent's first name">
                    <input v-model="form.parentFirstName" type="text" placeholder="" autocomplete="off"  name="parentFirstNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="parentLastName">
                  <label for="parentLastNameInput">Parent's last name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="parentLastName">
                    <input v-model="form.parentLastName" type="text" placeholder="" autocomplete="off"  name="parentLastNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="parentEmail">
                  <label for="parentEmailInput">Parent's email address</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Parent's email address">
                    <input v-model="form.parentEmail" type="text" placeholder="" autocomplete="off" name="parentEmailInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="childFirstName">
                  <label for="childFirstNameInput">Child's first name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="Child's first name">
                    <input v-model="form.childFirstName" type="text" placeholder="" autocomplete="off"  name="childFirstNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <b-alert variant="danger"
                  dismissible
                  :show="showDismissibleAlert"
                  @dismissed="showDismissibleAlert=false">
                  {{serverError}}
                </b-alert>
                <button type="submit" class="btn btn-warning btn-lg btn-block">Update details</button>
              </b-form>
            </ValidationObserver> 
          </div> 
        </div>
      </div>
    </div>

  </div>

  <Footer />
</div>

</template>

<script>
import accountDetails from '@/mixins/accountDetails'

export default {
  name: 'ProfileEdit',
  mixins: [accountDetails],
  props: ['id'],
  data () {
    return {
      deleteBlocker: '',
      form: {
        profileId: '',
        parentFirstName: '',
        parentLastName: '',
        parentEmail: '',
        childFirstName: ''
      },
      serverError: '',
      showDismissibleAlert: false
    }
  },
  computed: {
    userDisplay() {
      return `${this.form.parentFirstName} ${this.form.parentLastName} & ${this.form.childFirstName}`
    }
  },
  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = true
    await Promise.all([this.accountPersonalDetailsGet(), this.getParentProfile()]);
    this.$store.state.loadingProgress = false
  },
  beforeDestroy() {
  },
  methods: {
    deleteProfile() {
      console.log('Deleting account')
      this.$bvModal.hide('modal-delete-profile')
      this.$http({
        method: 'post', 
        url: this.$api.QuestionnaireProfilesDelete,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
        params: {
          profileId: this.form.id,
        }
      })
        .then((response) => {
          console.log(response.data)
          this.alertMsgShow("Profile deleted", true)
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
        })
    },

    getParentProfile() {
      this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireProfilesGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          this.form = response.data.find(profile => profile.id === this.id)
          if (!this.form) {
            this.alertMsgShow("Profile doesn't exist", false)
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
          this.$router.push('/dashboard')
        })
    },

    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },

    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.showDismissibleAlert = false
          console.log('Form validated ready to submit', this.form)
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.QuestionnaireProfilesUpdate,
            params: {
              profileId: this.form.id,
              ...this.form
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
            .then((response) => {
              console.log(response)
              this.alertMsgShow('Profile updated successfully', true)
            })
            .catch((error) => {
              console.error('Error', error)
              this.alertMsgShow('Something went wrong. Please try again.', false)
            })
            .finally(() => this.$store.state.loadingProgress = false)
        }
      });
    }
  }
}
</script>
